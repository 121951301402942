

import { defineComponent } from 'vue'
import ArticleDetail from './components/ArticleDetail.vue'

export default defineComponent({
  components: {
    ArticleDetail
  }
})
